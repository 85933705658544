<template>
  <div class="seopop">
    <div class="site-section bg-light">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center" style="margin-top: 60px;">
            <h2 class="section-title mb-3 text-normal-blue">M & A Consulting</h2>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-7 ml-auto pl-lg-7">
            <p class="mb-4 text-black">M&A is difficult, and traditional methods have proven to be ill-formed to derive success. Augment your capabilities, increase your likelihood of success and achieve synergies stated in the deal by including independent industry functional experts on your team that can provide real life advisory from the early stages of the deal all the way through due diligence, integration, and value generation and change your M&A game.</p>

            <p class="mb-4 text-black">Our M&A strategy consulting team implements proven processes and integration methodologies for engineering, health care,  professional and financial services, information technology, engineering, consumer products, and retail companies that want to transform their business, drive growth, and facilitate breakthrough ideas for exit planning, pre and post merger integrations.</p>

            <p class="mb-4 text-black">Traditional financial and market valuation-centric acquisition and merger  processes don’t work. On their own, they only provide analysis related to the financial viability of a deal, but financial analysis alone has led many organizations to fail. More often than not, crucial risks and overwhelming challenges lay hidden behind the financial analysis and they can only be found by strategic management and industry experts who know where to look. </p>

            <p class="mb-4 text-black">Companies that don’t incorporate independent industry experience early in the deal structure often find their deals to be lackluster transactions, never living up to their potential.</p>
 
            <p class="mb-4 text-black">Our M&A advisory services incorporate our deep industry expertise into your pre-deal activities, like the target search, due diligence process, deal negotiation, and transition services agreement (TSA)</p>

            <p class="mb-4 text-black">Clikry’s solutions can help you make sure all critical markers of success and failure are included in your M&A process and ensure you have the highest chance of achieving a successful deal for your shareholders.</p>
 
            <h2 class="text-normal-blue mb-4 h4 font-weight-bold">Deal Finding</h2>
            <p class="mb-4 text-black">Industry experts can help your company find hidden gems with newer and innovative lenses that could bring breakthrough deals to the table by:</p>
            <ul class="ul-check mb-5 list-unstyled success text-black">
              <li>Applying process and data automation, industry networks to deal search and targeting, and increase your organization’s spectrum of search</li>
              <li>Utilize different and innovative search filters to derive new potential targets and search uncommon platforms to uncover potential risks</li>
            </ul>

            <h2 class="text-normal-blue mb-4 h4 font-weight-bold">Due Diligence</h2>
            <p class="mb-4 text-black">Clickry’s strategy consultants bolster your ability to spot risks and critical failure points. Having industry experts in your deal team allows for independent evaluations across functions, ensuring there is enough information on critical items to structure the deal the right way.</p>
            <ul class="ul-check mb-5 list-unstyled success text-black">
              <li>Independent industry and sub-industry experts bring functional experience to complement financial analysis</li>
              <li>Create a realistic yet pragmatic Transitional Services Agreement (TSA)</li>
              <li>An industry focused analysis ensures you negotiate a fair deal price and avoid unnecessary costs and painful challenges downstream during the integration</li>
            </ul>

             <h2 class="text-normal-blue mb-4 h4 font-weight-bold">Integration Management Office</h2>
            <p class="mb-4 text-black">The role of the Integration Management Office (IMO) is to be that bridge ensuring execution and full integration is reached while balancing trade-offs, yet still hitting the strategic pillars of the deal. This includes IT, supply chain, and quality and regulatory.</p>
            <ul class="ul-check mb-5 list-unstyled success text-black">
              <li>The IMO ensures governance across functions and involved parties, manages risks and issues, and escalates them to corporate leadership while driving forward towards deal benefits realization</li>
              <li>The IMO uses the TSA as a guiding post for execution, and provides coordination and support across multiple workstreams</li>
            </ul>
          </div>
          <div class="col-lg-5" data-aos="fade-right">
            <br><br>
            <img src="images/undraw_Business_plan_re_0v81.svg" alt="Image" class="img-fluid">
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer
  }
};
</script>